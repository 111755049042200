<template>
    <div class="toasts-wrapper" v-if="toasts.length">
        <v-alert v-for="t in toasts" :type="t.type" class="mt-12" closable>
        {{ t.text }}
    </v-alert>
    </div>
</template>
  
  
  
<script setup lang="ts">
import { useToastStore } from '@/stores/toast';
const store = useToastStore();

const toasts = computed(() => {
return store.getToasts;
});
const timeout = computed(()=>{
    return store.getConfig.timeout;
});
onMounted(()=> {
  
  })
   
  watchEffect(()=>{
    if(store.getToasts.length > 0)
        {
            // Automatically dismiss toast after 5 seconds
            setTimeout(() => {
                dismissToast();
            }, timeout.value);
        }
    });
  const dismissToast = ()=> {
    store.clearToast();
    }
  
</script>

<style>
.toasts-wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    /* right: 1rem; */
    /* top: 1rem; */
    left: 50%;
    top: 1rem;
}
</style>
  