<script setup lang="ts">
import {watch} from 'vue';
import {RouterView} from 'vue-router';
import sidebarItem from '@/components/lc/Full/vertical-sidebar/sidebarItem';

const customizer = useCustomizer();
const title = ref(sidebarItem[1].title);

useHead({
  meta: [{content: title}],
  titleTemplate: () => {
    return title.value
        ? `${title.value} API - WiCoDocSrv`
        : "WiCoDocSrv";
  },
});
watch(customizer.actTheme, () => {

  customizer.setTheme(customizer.actTheme.value);

});
</script>

<template>
  <v-locale-provider>
    <v-app
        :theme="customizer.actTheme.value"
        :class="[
                customizer.actTheme.value,
                customizer.mini_sidebar.value ? 'mini-sidebar' : '',
                customizer.setHorizontalLayout.value ? 'horizontalLayout' : 'verticalLayout',
                customizer.setBorderCard.value ? 'cardBordered' : ''
            ]"
    >

      <LcFullCustomizer/>
      <LcFullVerticalSidebar v-if="!customizer.setHorizontalLayout.value"/>
      <LcFullVerticalHeader v-if="!customizer.setHorizontalLayout.value"/>

      <v-main>
        <v-container fluid class="page-wrapper pb-sm-15 pb-10">
          <div :class="customizer.boxed.value ? 'maxWidth' : ''">
            <SharedWCAlertModal/>
            <RouterView/>
          </div>
        </v-container>
      </v-main>
      <SharedWCToast/>
    </v-app>
  </v-locale-provider>
</template>
