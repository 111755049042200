<script setup lang="ts">
//@ts-nocheck
import {ref} from 'vue';

const primary = ref('rgb(var(--v-theme-primary))');
const secondary = ref('rgb(var(--v-theme-secondary))');
</script>
<template>
  <div class="logo">
    <NuxtLink to="/">
      <v-img width="150" height="80" class="" src="/images/logos/wico-logo.png">
      </v-img>
    </NuxtLink>
  </div>
</template>
