import { defineStore } from 'pinia';

interface toasts {
    toast: any;
    config: Object;
}

export const useToastStore = defineStore({
    id: 'toast',
    state: (): toasts => ({
        toast : [],
        config:{timeout:5000}
    }),
    getters: {
        getToasts(state) {return state.toast;},
        getConfig(state){ return state.config;}
    },
    actions: {
        addToast(toast: Object) {
          this.toast.push(toast);
        },
        clearToast() {
          this.toast = [];
        },
        setConfig(config:Object)
        {
            this.config = config;
        }
      },
      
});
