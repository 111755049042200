//@ts-nocheck
import {useState} from "#app";
import config from '@/config'
// import {useAuthStore} from "~/stores/core";

const useCustomizer = () => {
  const Sidebar_drawer = useState("Sidebar_drawer", () => config.Sidebar_drawer);
  const Customizer_drawer = useState("Customizer_drawer", () => config.Customizer_drawer);
  const mini_sidebar = useState("mini_sidebar", () => config.mini_sidebar);
  const setHorizontalLayout = useState("setHorizontalLayout", () => config.setHorizontalLayout);
  const actTheme = useState("actTheme", () => config.actTheme);
  const boxed = useState("boxed", () => config.boxed);
  const setBorderCard = useState("setBorderCard", () => config.setBorderCard);

  const getBoxed = () => {
    return boxed.value;
  }
  const setSideBarDrawer = () => {
    Sidebar_drawer.value = !Sidebar_drawer.value;
  }
  const setMiniSideBar = (payload: any) => {
    mini_sidebar.value = payload
  }

  const setTheme = (payload: any, stateSet = false) => {
    // const {getUserProfile} = useAuthStore();
    // const {setSettings} = useAuth();
    if (stateSet) actTheme.value = payload;
    // if (getUserProfile) {
    //   getUserProfile.user['settings']['mode'] = actTheme.value;
    //   setSettings();
    // }
  }
  const setCustomizerDrawer = (payload: any) => {
    Customizer_drawer.value = payload;
  }
  const getSideBarDrawer = () => {
    return Sidebar_drawer.value;
  }
  const getCusomtizerDraw = () => {
    return Customizer_drawer.value;
  }
  const getHorizontalLayout = () => {
    return setHorizontalLayout.value;
  }
  const getTheme = () => {
    return actTheme.value;
  }
  const getMiniSideBar = () => {
    return mini_sidebar.value;
  }
  const getBorderCard = () => {
    return setBorderCard.value;
  }

  return {
    setSideBarDrawer,
    setMiniSideBar,
    setTheme,
    getBoxed,
    getHorizontalLayout,
    getCusomtizerDraw,
    getTheme,
    getMiniSideBar,
    getBorderCard,
    getSideBarDrawer,
    setCustomizerDrawer,
    actTheme,
    Sidebar_drawer,
    setHorizontalLayout,
    mini_sidebar,
    Customizer_drawer,
    boxed,
    setBorderCard
  };
}
export default useCustomizer;