<script setup lang="ts">
import {ref} from 'vue';
import {useTheme} from 'vuetify';
import {
  LayoutDistributeHorizontalIcon,
  LayoutDistributeVerticalIcon,
  MoonIcon,
  SunHighIcon
} from 'vue-tabler-icons';

const theme = useTheme();
const customizer = useCustomizer();

// themes color options
const themeColors = ref([
  {
    name: 'BLUE_THEME',
    bg: 'themeBlue'
  },
  {
    name: 'AQUA_THEME',
    bg: 'themeAqua'
  },
  {
    name: 'PURPLE_THEME',
    bg: 'themePurple'
  },
  {
    name: 'GREEN_THEME',
    bg: 'themeGreen'
  },
  {
    name: 'CYAN_THEME',
    bg: 'themeCyan'
  },
  {
    name: 'ORANGE_THEME',
    bg: 'themeOrange'
  }
]);

// Dark Theme Colors
const DarkthemeColors = ref([
  {name: 'DARK_BLUE_THEME', bg: 'themeDarkBlue'},
  {name: 'DARK_AQUA_THEME', bg: 'themeDarkAqua'},
  {name: 'DARK_PURPLE_THEME', bg: 'themeDarkPurple'},
  {name: 'DARK_GREEN_THEME', bg: 'themeDarkGreen'},
  {name: 'DARK_CYAN_THEME', bg: 'themeDarkCyan'},
  {name: 'DARK_ORANGE_THEME', bg: 'themeDarkOrange'}
]);
</script>

<!------------------------------------->
<!-- Customizer -->
<!------------------------------------->
<template>
  <v-navigation-drawer app temporary elevation="10" location="right" v-model="customizer.Customizer_drawer.value"
                       width="320">
    <div class="pa-6">
      <h5 class="text-h5">Settings</h5>
    </div>
    <v-divider></v-divider>
    <perfect-scrollbar style="height: calc(100vh - 60px)">
      <div class="pa-6">
        <h6 class="text-h6 mt-11 mb-2">Theme</h6>
        <v-btn-toggle v-model="customizer.actTheme.value" color="primary" class="my-2 btn-group-custom" rounded="0"
                      group>
          <v-btn value="GREEN_THEME" variant="text" elevation="9" class="rounded-md">
            <SunHighIcon stroke-width="1.5" size="21" class="mr-2"/>
            Light
          </v-btn>
          <v-btn value="DARK_GREEN_THEME" variant="text" elevation="9" class="rounded-md ml-4">
            <MoonIcon stroke-width="1.5" size="21" class="mr-2"/>
            Dark
          </v-btn>
        </v-btn-toggle>
        <h6 class="text-h6 mt-11 mb-2">Container Option</h6>
        <v-btn-toggle v-model="customizer.boxed.value" color="primary" class="my-2 btn-group-custom" rounded="0" group>
          <v-btn :value="true" variant="text" elevation="9" class="rounded-md">
            <LayoutDistributeVerticalIcon stroke-width="1.5" size="21" class="mr-2"/>
            Boxed
          </v-btn>
          <v-btn :value="false" variant="text" elevation="9" class="rounded-md ml-4">
            <LayoutDistributeHorizontalIcon stroke-width="1.5" size="21" class="mr-2"/>
            Full
          </v-btn>
        </v-btn-toggle>
      </div>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>

<style lang="scss"></style>
