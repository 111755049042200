<script setup lang="ts">
import {shallowRef} from 'vue';
import sidebarItems from './sidebarItem';

const customizer = useCustomizer();
const sidebarMenu = shallowRef(sidebarItems);

</script>

<template>
  <v-navigation-drawer left v-model="customizer.Sidebar_drawer.value" elevation="0" rail-width="75"
                       mobile-breakpoint="960" app class="leftSidebar" :rail="customizer.mini_sidebar.value"
                       expand-on-hover width="270">
    <!---Logo part -->
    <div class="pa-5">
      <LcFullLogoSidebar/>
    </div>
    <!-- ---------------------------------------------- -->
    <!---Navigation -->
    <!-- ---------------------------------------------- -->
    <perfect-scrollbar class="scrollnavbar">
      <v-list class="pa-6" density="compact">
        <!---Menu Loop -->
        <template v-for="item in sidebarMenu">
          <!---Item Sub Header -->
          <LcFullVerticalSidebarNavGroup :item="item" v-if="item.header" :key="item.title"/>
          <!---If Has Child -->
          <LcFullVerticalSidebarNavCollapse class="leftPadding" :item="item" :level="0" v-else-if="item.children"/>
          <!---Single Item-->
          <LcFullVerticalSidebarNavItem :item="item" v-else class="leftPadding"/>
          <!---End Single Item-->
        </template>
      </v-list>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>
