import { ApiIcon } from "vue-tabler-icons";

export interface menu {
  header?: string;
  module?: string;
  title?: string;
  icon?: any;
  to?: string;
  chip?: string;
  chipBgColor?: string;
  chipColor?: string;
  chipVariant?: string;
  chipIcon?: string;
  children?: menu[];
  disabled?: boolean;
  type?: string;
  subCaption?: string;
  role?: string;
}

const sidebarItem: menu[] = [
  { header: "Doc - APIs", module: "docapis" },
  { title: "Balance & Voucher", icon: ApiIcon, to: "/apis/balvou", module:'core', role:'CORESYSSUP' },
];

export default sidebarItem;
