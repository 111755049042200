<script setup lang="ts">
import {ref, watch} from 'vue';

// Icon Imports
import {Menu2Icon, SettingsIcon} from 'vue-tabler-icons';

const customizer = useCustomizer();
const priority = ref(customizer.setHorizontalLayout.value ? 0 : 0);
watch(priority, (newPriority) => {
  priority.value = newPriority;
});
</script>

<template>
  <v-app-bar elevation="0" :priority="priority" height="70" class="">
    <v-btn class="hidden-md-and-down custom-hover-primary" icon color="primary" variant="text"
           @click.stop="customizer.setMiniSideBar(!customizer.mini_sidebar.value)">
      <Menu2Icon size="20" stroke-width="1.5"/>
    </v-btn>
    <v-btn class="hidden-lg-and-up ms-3" icon variant="flat" @click.stop="customizer.setSideBarDrawer()"
           size="small">
      <Menu2Icon size="20" stroke-width="1.5"/>
    </v-btn>
    <v-spacer/>
<!--    <LcFullVerticalHeaderLanguageDD/>-->

<!--    <div class="ml-1">-->
<!--      <v-btn icon color="primary" class="custom-hover-primary"-->
<!--             @click.stop="customizer.setCustomizerDrawer(!customizer.Customizer_drawer.value)">-->
<!--        <SettingsIcon stroke-width="1.5" size="25"/>-->
<!--      </v-btn>-->
<!--    </div>-->
  </v-app-bar>
</template>
